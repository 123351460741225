import {
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_CATEGORIES_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllProductCategories = ({offset, limit}) => ({
    type: GET_PRODUCT_CATEGORIES,
    payload: { offset, limit }
});

export const getAllProductCategoriesSuccess = ({total, data}) => ({
    type: GET_PRODUCT_CATEGORIES_SUCCESS,
    payload: {total, data}
});

export const getAllProductCategoriesFailed = (error) => ({
    type: GET_PRODUCT_CATEGORIES_FAILED,
    payload: error
});