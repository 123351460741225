import {
    GET_NEWS,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILED,

    GET_NEWS_ITEM,
    GET_NEWS_ITEM_SUCCESS,
    GET_NEWS_ITEM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    news: [],
    currentNewsItem: null,
    error: null,
    deleting: false
};

const NewsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEWS:
            return { ...state, loading: true };
        case GET_NEWS_SUCCESS:
            return { ...state, news: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_NEWS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_NEWS_ITEM:
            return { ...state, loading: true };
        case GET_NEWS_ITEM_SUCCESS:
            return { ...state, currentNewsItem: action.payload.data, loading: false, error: null };
        case GET_NEWS_ITEM_FAILED:
            return { ...state, error: action.payload, loading: false };
            
        default: return { ...state };
    }
}

export default NewsReducer;