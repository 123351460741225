import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_PRODUCTS, 
    GET_PRODUCT
} from '../../constants/actionTypes';


import {
    getAllProductsSuccess,
    getAllProductsFailed,

    getProductSuccess,
    getProductFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/alumni/products`;

function* getAllProducts({ payload: { offset, limit, filter = {} } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.id) {
            url = `${url}&exclude=${filter.id}`
        }
        if(filter.random) {
            url = `${url}&random=${filter.random}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllProductsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllProductsFailed(message));
    }
}


function* getProduct({ payload: id }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/${id}`;
        const {data} = yield call(fetchJSON, url, options);
        
        yield put(getProductSuccess({data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getProductFailed(message));
    }
}


export function* watchGetAllProducts() {
    yield takeEvery(GET_PRODUCTS, getAllProducts);
}

export function* watchGetProduct() {
    yield takeEvery(GET_PRODUCT, getProduct);
}


function* productSaga() {
    yield all([
        fork(watchGetAllProducts),
        fork(watchGetProduct),
    ]);
}

export default productSaga;