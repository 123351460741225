import {
    SUBMIT_CHANGE_PASSWORD_FORM,
    SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
    SUBMIT_CHANGE_PASSWORD_FORM_FAILED,

    SUBMIT_UPDATE_PROFILE_FORM,
    SUBMIT_UPDATE_PROFILE_FORM_SUCCESS,
    SUBMIT_UPDATE_PROFILE_FORM_FAILED,

    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    submitting: false,
    error: null,
    currentUser: null
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBMIT_CHANGE_PASSWORD_FORM:
            return { ...state, error: null, submitting: true };
        case SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS:
            return { ...state, error: null, submitting: false };
        case SUBMIT_CHANGE_PASSWORD_FORM_FAILED:
            return { ...state, error: action.payload, submitting: false };

        case SUBMIT_UPDATE_PROFILE_FORM:
            return { ...state, error: null, submitting: true };
        case SUBMIT_UPDATE_PROFILE_FORM_SUCCESS:
            return { ...state, error: null, submitting: false };
        case SUBMIT_UPDATE_PROFILE_FORM_FAILED:
            return { ...state, error: action.payload, submitting: false };

        case GET_CURRENT_USER:
            return { ...state, loading: true };
        case GET_CURRENT_USER_SUCCESS:
            return { ...state, currentUser: action.payload.data, loading: false, error: null };
        case GET_CURRENT_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
            
        default: return { ...state };
    }
}

export default UserReducer;