import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILED,

    GET_PARTNER_ITEM,
    GET_PARTNER_ITEM_SUCCESS,
    GET_PARTNER_ITEM_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllPartners = ({offset, limit}) => ({
    type: GET_PARTNERS,
    payload: { offset, limit }
});

export const getAllPartnersSuccess = ({total, data}) => ({
    type: GET_PARTNERS_SUCCESS,
    payload: {total, data}
});

export const getAllPartnersFailed = (error) => ({
    type: GET_PARTNERS_FAILED,
    payload: error
});

/** GET ONE */
export const getPartnerItem = (id) => ({
    type: GET_PARTNER_ITEM,
    payload: id
});

export const getPartnerItemSuccess = (data) => ({
    type: GET_PARTNER_ITEM_SUCCESS,
    payload: data
});

export const getPartnerItemFailed = (error) => ({
    type: GET_PARTNER_ITEM_FAILED,
    payload: error
});
