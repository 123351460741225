import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_NEWS, 
    GET_NEWS_ITEM
} from '../../constants/actionTypes';


import {
    getAllNewsSuccess,
    getAllNewsFailed,

    getNewsItemSuccess,
    getNewsItemFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/news`;

function* getAllNews({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllNewsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllNewsFailed(message));
    }
}

function* getNewsItem({ payload: id }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/${id}`;
        const {data} = yield call(fetchJSON, url, options);
        yield put(getNewsItemSuccess({data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getNewsItemFailed(message));
    }
}


export function* watchGetAllNews() {
    yield takeEvery(GET_NEWS, getAllNews);
}
export function* watchGetNewsItem() {
    yield takeEvery(GET_NEWS_ITEM, getNewsItem);
}


function* serviceSaga() {
    yield all([
        fork(watchGetAllNews),
        fork(watchGetNewsItem)
    ]);
}

export default serviceSaga;