import {
    GET_NEWS,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAILED,

    GET_NEWS_ITEM,
    GET_NEWS_ITEM_SUCCESS,
    GET_NEWS_ITEM_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllNews = ({offset, limit}) => ({
    type: GET_NEWS,
    payload: { offset, limit }
});

export const getAllNewsSuccess = ({total, data}) => ({
    type: GET_NEWS_SUCCESS,
    payload: {total, data}
});

export const getAllNewsFailed = (error) => ({
    type: GET_NEWS_FAILED,
    payload: error
});

/** GET ONE */
export const getNewsItem = (id) => ({
    type: GET_NEWS_ITEM,
    payload: id
});

export const getNewsItemSuccess = (data) => ({
    type: GET_NEWS_ITEM_SUCCESS,
    payload: data
});

export const getNewsItemFailed = (error) => ({
    type: GET_NEWS_ITEM_FAILED,
    payload: error
});
