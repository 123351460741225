import {
    GET_DEALS,
    GET_DEALS_SUCCESS,
    GET_DEALS_FAILED,

    GET_DEAL_ITEM,
    GET_DEAL_ITEM_SUCCESS,
    GET_DEAL_ITEM_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllDeals = ({offset, limit, filter }) => ({
    type: GET_DEALS,
    payload: { offset, limit, filter }
});

export const getAllDealsSuccess = ({total, data}) => ({
    type: GET_DEALS_SUCCESS,
    payload: {total, data}
});

export const getAllDealsFailed = (error) => ({
    type: GET_DEALS_FAILED,
    payload: error
});

/** GET ONE */
export const getDealItem = (id) => ({
    type: GET_DEAL_ITEM,
    payload: id
});

export const getDealItemSuccess = (data) => ({
    type: GET_DEAL_ITEM_SUCCESS,
    payload: data
});

export const getDealItemFailed = (error) => ({
    type: GET_DEAL_ITEM_FAILED,
    payload: error
});
