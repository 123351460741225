import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILED,

    GET_PRODUCT,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILED
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllProducts = ({offset, limit, filter = {}}) => ({
    type: GET_PRODUCTS,
    payload: { offset, limit, filter }
});

export const getAllProductsSuccess = ({total, data}) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: {total, data}
});

export const getAllProductsFailed = (error) => ({
    type: GET_PRODUCTS_FAILED,
    payload: error
});


/** GET ONE */
export const getProduct = (id) => ({
    type: GET_PRODUCT,
    payload: id
});

export const getProductSuccess = (data) => ({
    type: GET_PRODUCT_SUCCESS,
    payload: data
});

export const getProductFailed = (error) => ({
    type: GET_PRODUCT_FAILED,
    payload: error
});
