import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_PARTNERS, 
    GET_PARTNER_ITEM
} from '../../constants/actionTypes';


import {
    getAllPartnersSuccess,
    getAllPartnersFailed,

    getPartnerItemSuccess,
    getPartnerItemFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/partners`;

function* getAllPartners({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllPartnersSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllPartnersFailed(message));
    }
}

function* getPartnerItem({ payload: id }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/${id}`;
        const {data} = yield call(fetchJSON, url, options);
        
        yield put(getPartnerItemSuccess({data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getPartnerItemFailed(message));
    }
}

export function* watchGetAllPartners() {
    yield takeEvery(GET_PARTNERS, getAllPartners);
}
export function* watchGetPartnerItem() {
    yield takeEvery(GET_PARTNER_ITEM, getPartnerItem);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllPartners),
        fork(watchGetPartnerItem),
    ]);
}

export default serviceSaga;