import {
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllCountries = ({offset, limit}) => ({
    type: GET_COUNTRY,
    payload: { offset, limit }
});

export const getAllCountriesSuccess = ({total, data}) => ({
    type: GET_COUNTRY_SUCCESS,
    payload: {total, data}
});

export const getAllCountriesFailed = (error) => ({
    type: GET_COUNTRY_FAILED,
    payload: error
});