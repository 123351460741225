import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import branchSaga from './branch/saga';
import serviceSaga from './service/saga';
import newsSaga from './news/saga';
import countrySaga from './country/saga';
import partnerSaga from './partner/saga';
import dealSaga from './deal/saga';
import authUserSaga from './authUser/saga';
import transactionSaga from './transaction/saga';
import productSaga from './product/saga';
import productCategorySaga from './productCategory/saga';
import referralSaga from './referral/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        branchSaga(),
        serviceSaga(),
        newsSaga(),
        countrySaga(),
        partnerSaga(),
        dealSaga(),
        authUserSaga(),
        transactionSaga(),
        productSaga(),
        productCategorySaga(),
        referralSaga()
    ]);
}
