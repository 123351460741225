import {
    GET_BRANCHES,
    GET_BRANCHES_SUCCESS,
    GET_BRANCHES_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    branches: [],
    error: null,
};

const BranchReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BRANCHES:
            return { ...state, loading: true };
        case GET_BRANCHES_SUCCESS:
            return { ...state, branches: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_BRANCHES_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default BranchReducer;