import {
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_CATEGORIES_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    productCategories: [],
};

const CategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_CATEGORIES:
            return { ...state, loading: true };
        case GET_PRODUCT_CATEGORIES_SUCCESS:
            return { ...state, productCategories: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_PRODUCT_CATEGORIES_FAILED:
            return { ...state, error: action.payload, loading: false };

        default: return { ...state };
    }
}

export default CategoryReducer;