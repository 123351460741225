import {
    GET_BRANCHES,
    GET_BRANCHES_SUCCESS,
    GET_BRANCHES_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllBranches = ({offset, limit}) => ({
    type: GET_BRANCHES,
    payload: { offset, limit }
});

export const getAllBranchesSuccess = ({total, data}) => ({
    type: GET_BRANCHES_SUCCESS,
    payload: {total, data}
});

export const getAllBranchesFailed = (error) => ({
    type: GET_BRANCHES_FAILED,
    payload: error
});
