import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILED,

    SUBMIT_TRANSACTION_FORM,
    SUBMIT_TRANSACTION_FORM_SUCCESS,
    SUBMIT_TRANSACTION_FORM_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllTransactions = ({offset, limit}) => ({
    type: GET_TRANSACTIONS,
    payload: { offset, limit }
});

export const getAllTransactionsSuccess = ({total, data}) => ({
    type: GET_TRANSACTIONS_SUCCESS,
    payload: {total, data}
});

export const getAllTransactionsFailed = (error) => ({
    type: GET_TRANSACTIONS_FAILED,
    payload: error
});

/** SUBMIT FORM */
export const submitTransactionForm = (transaction) => ({
    type: SUBMIT_TRANSACTION_FORM,
    payload: transaction
});

export const submitTransactionFormSuccess = (transaction) => ({
    type: SUBMIT_TRANSACTION_FORM_SUCCESS,
    payload: transaction
});

export const submitTransactionFormFailed = (error) => ({
    type: SUBMIT_TRANSACTION_FORM_FAILED,
    payload: error
});