import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILED,
} from '../../constants/actionTypes'; 



/** GET ALL */
export const getAllReferrals = ({offset, limit, filter}) => ({
    type: GET_REFERRALS,
    payload: { offset, limit, filter }
});

export const getAllReferralsSuccess = ({total, data}) => ({
    type: GET_REFERRALS_SUCCESS,
    payload: {total, data}
});

export const getAllReferralsFailed = (error) => ({
    type: GET_REFERRALS_FAILED,
    payload: error
});
