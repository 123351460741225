import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_REFERRALS,
} from '../../constants/actionTypes';


import {
    getAllReferralsSuccess,
    getAllReferralsFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/alumni`;


function* getAllReferrals({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}/referrals?offset=${offset}&limit=${limit}`;
        if(filter.query) {
            url = `${url}&query=${filter.query}`
        }
        if(filter.branchId) {
            url = `${url}&branchId=${filter.branchId}`
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllReferralsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllReferralsFailed(message));
    }
}


export function* watchGetAllReferrals() {
    yield takeEvery(GET_REFERRALS, getAllReferrals);
}


function* referralSaga() {
    yield all([
        fork(watchGetAllReferrals),
    ]);
}

export default referralSaga;