import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Service from './service/reducers';
import Branch from './branch/reducers';
import News from './news/reducers';
import Country from './country/reducers';
import Partner from './partner/reducers';
import Deal from './deal/reducers';
import AuthUser from './authUser/reducers';
import Transaction from './transaction/reducers';
import Product from './product/reducers';
import ProductCategory from './productCategory/reducers';
import Referral from './referral/reducers';

export default combineReducers({
    Auth,
    Service,
    Branch,
    News,
    Country,
    Partner,
    Deal,
    AuthUser,
    Transaction,
    Product,
    ProductCategory,
    Referral
});