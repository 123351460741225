import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';

import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    SUBMIT_CHANGE_PASSWORD_FORM, 
    SUBMIT_UPDATE_PROFILE_FORM,
    GET_CURRENT_USER
} from '../../constants/actionTypes';


import {
    submitChangePasswordFormSuccess,
    submitChangePasswordFormFailed,

    submitUpdateProfileFormSuccess,
    submitUpdateProfileFormFailed,

    getCurrentUserSuccess,
    getCurrentUserFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/alumni/current-user`;

function* submitChangePassword({ payload: user }) {
    
    const options = {
        body: JSON.stringify(user),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/change-password`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            cogoToast.success(response.message);
            yield put(submitChangePasswordFormSuccess(response.data));
        }
        else {
            cogoToast.error(response.message);
            yield put(submitChangePasswordFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitChangePasswordFormFailed(message));
    }
}

function* submitUpdateProfile({ payload: user }) {
    
    const options = {
        body: JSON.stringify(user),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}/update-profile`;
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            cogoToast.success(response.message);
            yield put(submitUpdateProfileFormSuccess(response.data));
        }
        else {
            cogoToast.error(response.message);
            yield put(submitChangePasswordFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitUpdateProfileFormFailed(message));
    }
}

function* getCurrentUser() {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/`;
        const {data} = yield call(fetchJSON, url, options);
        yield put(getCurrentUserSuccess({data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getCurrentUserFailed(message));
    }
}



export function* watchSubmitChangePasswordForm() {
    yield takeEvery(SUBMIT_CHANGE_PASSWORD_FORM, submitChangePassword);
}

export function* watchSubmitUpdateProfileForm() {
    yield takeEvery(SUBMIT_UPDATE_PROFILE_FORM, submitUpdateProfile);
}

export function* watchGetCurrentUserForm() {
    yield takeEvery(GET_CURRENT_USER, getCurrentUser);
}


function* currentUserSaga() {
    yield all([
        fork(watchSubmitChangePasswordForm),
        fork(watchSubmitUpdateProfileForm),
        fork(watchGetCurrentUserForm),
    ]);
}

export default currentUserSaga;