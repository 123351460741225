import {
    GET_DEALS,
    GET_DEALS_SUCCESS,
    GET_DEALS_FAILED,

    GET_DEAL_ITEM,
    GET_DEAL_ITEM_SUCCESS,
    GET_DEAL_ITEM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    deals: [],
    currentDealItem: null,
    error: null,
};

const DealReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DEALS:
            return { ...state, loading: true };
        case GET_DEALS_SUCCESS:
            return { ...state, deals: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_DEALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_DEAL_ITEM:
            return { ...state, loading: true };
        case GET_DEAL_ITEM_SUCCESS:
            return { ...state, currentDealItem: action.payload.data, loading: false, error: null };
        case GET_DEAL_ITEM_FAILED:
            return { ...state, error: action.payload, loading: false };

        default: return { ...state };
    }
}

export default DealReducer;