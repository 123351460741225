import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_SERVICES, 
} from '../../constants/actionTypes';


import {
    getAllServicesSuccess,
    getAllServicesFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/services`;

function* getAllServices({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        
        yield put(getAllServicesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllServicesFailed(message));
    }
}

export function* watchGetAllServices() {
    yield takeEvery(GET_SERVICES, getAllServices);
}

function* serviceSaga() {
    yield all([
        fork(watchGetAllServices)
    ]);
}

export default serviceSaga;