import {
    GET_COUNTRY,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    countries: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const CountryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRY:
            return { ...state, loading: true };
        case GET_COUNTRY_SUCCESS:
            return { ...state, countries: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_COUNTRY_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default CountryReducer;