/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';


export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
/* BRANCH  */
export const GET_BRANCHES = 'GET_BRANCHES';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILED = 'GET_BRANCHES_FAILED';


/* SERVICE  */
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILED = 'GET_SERVICES_FAILED';

/* NEWS  */
export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILED = 'GET_NEWS_FAILED';

export const GET_NEWS_ITEM = 'GET_NEWS_ITEM';
export const GET_NEWS_ITEM_SUCCESS = 'GET_NEWS_ITEM_SUCCESS';
export const GET_NEWS_ITEM_FAILED = 'GET_NEWS_ITEM_FAILED';

/* COUNTRY  */
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED';

/* PARTNER  */
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAILED = 'GET_PARTNERS_FAILED';

export const GET_PARTNER_ITEM = 'GET_PARTNER_ITEM';
export const GET_PARTNER_ITEM_SUCCESS = 'GET_PARTNER_ITEM_SUCCESS';
export const GET_PARTNER_ITEM_FAILED = 'GET_PARTNER_ITEM_FAILED';


/* DEAL  */
export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_FAILED = 'GET_DEALS_FAILED';

export const GET_DEAL_ITEM = 'GET_DEAL_ITEM';
export const GET_DEAL_ITEM_SUCCESS = 'GET_DEAL_ITEM_SUCCESS';
export const GET_DEAL_ITEM_FAILED = 'GET_DEAL_ITEM_FAILED';

/* AUTH USER  */
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILED = 'GET_CURRENT_USER_FAILED';

export const SUBMIT_CHANGE_PASSWORD_FORM = 'SUBMIT_CHANGE_PASSWORD_FORM';
export const SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS = 'SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS';
export const SUBMIT_CHANGE_PASSWORD_FORM_FAILED = 'SUBMIT_CHANGE_PASSWORD_FORM_FAILED';

export const SUBMIT_UPDATE_PROFILE_FORM = 'SUBMIT_UPDATE_PROFILE_FORM';
export const SUBMIT_UPDATE_PROFILE_FORM_SUCCESS = 'SUBMIT_UPDATE_PROFILE_FORM_SUCCESS';
export const SUBMIT_UPDATE_PROFILE_FORM_FAILED = 'SUBMIT_UPDATE_PROFILE_FORM_FAILED';


/* TRANSACTION  */
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

export const SUBMIT_TRANSACTION_FORM = 'SUBMIT_TRANSACTION_FORM';
export const SUBMIT_TRANSACTION_FORM_SUCCESS = 'SUBMIT_TRANSACTION_FORM_SUCCESS';
export const SUBMIT_TRANSACTION_FORM_FAILED = 'SUBMIT_TRANSACTION_FORM_FAILED';


/* PRODUCTS  */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

/* PRODUCT CATEGORY  */
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAILED = 'GET_PRODUCT_CATEGORIES_FAILED';

/* REFERRAL  */
export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILED = 'GET_REFERRALS_FAILED';