import {
    SUBMIT_CHANGE_PASSWORD_FORM,
    SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
    SUBMIT_CHANGE_PASSWORD_FORM_FAILED,

    SUBMIT_UPDATE_PROFILE_FORM,
    SUBMIT_UPDATE_PROFILE_FORM_SUCCESS,
    SUBMIT_UPDATE_PROFILE_FORM_FAILED,

    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILED
} from '../../constants/actionTypes'; 


/** SUBMIT CHANGE PASSWORD FORM */
export const submitChangePasswordForm = (user) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM,
    payload: user
});

export const submitChangePasswordFormSuccess = (user) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
    payload: user
});

export const submitChangePasswordFormFailed = (error) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM_FAILED,
    payload: error
});

/** SUBMIT UPDATE PROFILE FORM */
export const submitUpdateProfileForm = (user) => ({
    type: SUBMIT_UPDATE_PROFILE_FORM,
    payload: user
});

export const submitUpdateProfileFormSuccess = (user) => ({
    type: SUBMIT_UPDATE_PROFILE_FORM_SUCCESS,
    payload: user
});

export const submitUpdateProfileFormFailed = (error) => ({
    type: SUBMIT_UPDATE_PROFILE_FORM_FAILED,
    payload: error
});

/** GET CURRENT USER */
export const getCurrentUser = (id) => ({
    type: GET_CURRENT_USER,
    payload: id
});

export const getCurrentUserSuccess = (data) => ({
    type: GET_CURRENT_USER_SUCCESS,
    payload: data
});

export const getCurrentUserFailed = (error) => ({
    type: GET_CURRENT_USER_FAILED,
    payload: error
});
