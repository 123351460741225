import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAILED,

    SUBMIT_TRANSACTION_FORM,
    SUBMIT_TRANSACTION_FORM_SUCCESS,
    SUBMIT_TRANSACTION_FORM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    transactions: [],
    submittingForm: false,
    error: null,
    deleting: false
};

const TransactionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRANSACTIONS:
            return { ...state, loading: true };
        case GET_TRANSACTIONS_SUCCESS:
            return { ...state, transactions: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_TRANSACTIONS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case SUBMIT_TRANSACTION_FORM:
            return { ...state, error: null, submittingForm: true };
        case SUBMIT_TRANSACTION_FORM_SUCCESS:
            if(action.payload && action.payload.id) {
                const alreadyExists = state.transactions.find((transaction) => transaction.id === action.payload.id);
                let transactions;
                let total = state.total;
                if (!alreadyExists) {
                    transactions = [action.payload, ...state.transactions];
                    total += 1;
                } else {
                        transactions = state.transactions.map((transaction) => {
                        if (transaction.id === action.payload.id) {
                        return action.payload;
                        }
                        return transaction;
                    });
                }
                return { ...state, total,  transactions, submittingForm: false };
            }
            return { ...state, submittingForm: false };
        
        case SUBMIT_TRANSACTION_FORM_FAILED:
        return { ...state, error: action.payload, submittingForm: false };
            
        default: return { ...state };
    }
}

export default TransactionReducer;