import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_DEALS, 
    GET_DEAL_ITEM
} from '../../constants/actionTypes';


import {
    getAllDealsSuccess,
    getAllDealsFailed,

    getDealItemSuccess,
    getDealItemFailed
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/deals`;

function* getAllDeals({ payload: { offset, limit, filter } }) {
    const options = {
        method: 'GET',
    };

    try {
        let url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        if(filter.partnerId) {
            url = `${url}&partnerId=${filter.partnerId}`;
        }
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllDealsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllDealsFailed(message));
    }
}


function* getDealItem({ payload: id }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}/${id}`;
        const {data} = yield call(fetchJSON, url, options);
        
        yield put(getDealItemSuccess({data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getDealItemFailed(message));
    }
}


export function* watchGetAllDeals() {
    yield takeEvery(GET_DEALS, getAllDeals);
}

export function* watchGetDealItem() {
    yield takeEvery(GET_DEAL_ITEM, getDealItem);
}


function* serviceSaga() {
    yield all([
        fork(watchGetAllDeals),
        fork(watchGetDealItem),
    ]);
}

export default serviceSaga;