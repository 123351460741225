import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_TRANSACTIONS, 
    SUBMIT_TRANSACTION_FORM,
} from '../../constants/actionTypes';


import {
    getAllTransactionsSuccess,
    getAllTransactionsFailed,

    submitTransactionFormSuccess,
    submitTransactionFormFailed,

} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/alumni/transactions`;

function* getAllTransactions({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllTransactionsSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllTransactionsFailed(message));
    }
}


function* submitTransaction({ payload: transaction }) {
    const options = {
        body: JSON.stringify(transaction),
        method: transaction.id ? 'PUT' :  'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        let url = `${apiUrl}`;
        if(transaction.id) {
            url = `${apiUrl}/${transaction.id}`;
        }
        const response = yield call(fetchJSON, url, options);
        if(response.status === 'OK') {
            yield put(submitTransactionFormSuccess(response.data));
        }
        else {
            yield put(submitTransactionFormFailed(response.message));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(submitTransactionFormFailed(message));
    }
}


export function* watchGetAllTransactions() {
    yield takeEvery(GET_TRANSACTIONS, getAllTransactions);
}
export function* watchSubmitTransactionForm() {
    yield takeEvery(SUBMIT_TRANSACTION_FORM, submitTransaction);
}


function* transactionSaga() {
    yield all([
        fork(watchGetAllTransactions),
        fork(watchSubmitTransactionForm),
    ]);
}

export default transactionSaga;