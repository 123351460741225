import {
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILED,

    GET_PRODUCT,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    products: [],
    submittingForm: false,
    error: null,
    deleting: false,
    currentProduct: null
};

const ProductReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return { ...state, loading: true };
        case GET_PRODUCTS_SUCCESS:
            return { ...state, products: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_PRODUCTS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PRODUCT:
            return { ...state, loading: true };
        case GET_PRODUCT_SUCCESS:
            return { ...state, currentProduct: action.payload.data, loading: false, error: null };
        case GET_PRODUCT_FAILED:
            return { ...state, error: action.payload, loading: false };
            
        default: return { ...state };
    }
}

export default ProductReducer;