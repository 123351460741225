import {
    GET_SERVICES,
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    services: [],
    error: null,
    deleting: false
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SERVICES:
            return { ...state, loading: true };
        case GET_SERVICES_SUCCESS:
            return { ...state, services: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_SERVICES_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default reducer;