import {
    GET_SERVICES,
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILED,
} from '../../constants/actionTypes';


/** GET ALL */
export const getAllServices = ({offset, limit}) => ({
    type: GET_SERVICES,
    payload: { offset, limit }
});

export const getAllServicesSuccess = ({total, data}) => ({
    type: GET_SERVICES_SUCCESS,
    payload: {total, data}
});

export const getAllServicesFailed = (error) => ({
    type: GET_SERVICES_FAILED,
    payload: error
});