import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILED,

    GET_PARTNER_ITEM,
    GET_PARTNER_ITEM_SUCCESS,
    GET_PARTNER_ITEM_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    partners: [],
    currentPartnerItem: null,
    error: null,
};

const PartnerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PARTNERS:
            return { ...state, loading: true };
        case GET_PARTNERS_SUCCESS:
            return { ...state, partners: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_PARTNERS_FAILED:
            return { ...state, error: action.payload, loading: false };

        case GET_PARTNER_ITEM:
            return { ...state, loading: true };
        case GET_PARTNER_ITEM_SUCCESS:
            return { ...state, currentPartnerItem: action.payload.data, loading: false, error: null };
        case GET_PARTNER_ITEM_FAILED:
            return { ...state, error: action.payload, loading: false };

        default: return { ...state };
    }
}

export default PartnerReducer;