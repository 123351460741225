import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON, apiRootEndpoint } from '../../helpers';
import {
    GET_COUNTRY, 
} from '../../constants/actionTypes';


import {
    getAllCountriesSuccess,
    getAllCountriesFailed,
} from './actions';

const apiUrl = `${apiRootEndpoint}/api/v1/portal/countries`;

function* getAllCountries({ payload: { offset, limit } }) {
    const options = {
        method: 'GET',
    };

    try {
        const url = `${apiUrl}?offset=${offset}&limit=${limit}`;
        const {total, data} = yield call(fetchJSON, url, options);
        yield put(getAllCountriesSuccess({total, data}));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            default: message = error;
        }
        yield put(getAllCountriesFailed(message));
    }
}



export function* watchGetAllCountries() {
    yield takeEvery(GET_COUNTRY, getAllCountries);
}

function* countrySaga() {
    yield all([
        fork(watchGetAllCountries),
    ]);
}

export default countrySaga;