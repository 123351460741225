import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated } from './helpers/authUtils';

// lazy load all the views
const Landing = React.lazy(() => import('./pages/Landing/'));
const About = React.lazy(() => import('./pages/About'));
const News = React.lazy(() => import('./pages/News/'));
const NewsDetails = React.lazy(() => import('./pages/NewsDetails'));
const Partners = React.lazy(() => import('./pages/Partners/'));
const PartnerDetails = React.lazy(() => import('./pages/PartnerDetails'));
const Deals = React.lazy(() => import('./pages/Deals/'));
const DealDetails = React.lazy(() => import('./pages/DealDetails'));
const Contact = React.lazy(() => import('./pages/Contact/'));
const FAQs = React.lazy(() => import('./pages/FAQs/'));
const Error404 = React.lazy(() => import('./pages/Error404/'));

// auth
const JoinNow = React.lazy(() => import('./pages/Auth/JoinNow'));
const LogIn = React.lazy(() => import('./pages/Auth/LogIn'));
const Logout = React.lazy(() => import('./pages/Auth/Logout'));
const ForgotPassword = React.lazy(() => import('./pages/Auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/Auth/ResetPassword'));

const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const UpdateProfile = React.lazy(() => import('./pages/UpdateProfile'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const ProductDetails = React.lazy(() => import('./pages/ProductDetails'));
const ReferAFriend = React.lazy(() => import('./pages/ReferAFriend'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    }
    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
 
  { path: '/about', name: 'About', component: About, route: Route },
  { path: '/news', exact: true, name: 'News', component: News, route: Route },
  { path: '/news/:id', exact:true, name: 'NewsDetails', component: NewsDetails, route: Route },
  { path: '/partners', exact:true, name: 'Partners', component: Partners, route: Route },
  { path: '/partners/:id', exact:true, name: 'PartnerDetails', component: PartnerDetails, route: Route },
  { path: '/deals', exact: true, name: 'Deals', component: Deals, route: Route },
  { path: '/deals/:id', exact: true, name: 'DealDetails', component: DealDetails, route: Route },
  { path: '/contact', name: 'Contact', component: Contact, route: Route },
  { path: '/faqs', name: 'FAQs', component: FAQs, route: Route },
  { path: '/404', name: 'Error404', component: Error404, route: Route },
  
  { path: '/join-now', name: 'Join Now', component: JoinNow, route: Route },
  { path: '/login', name: 'Log In', component: LogIn, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword, route: Route },
  { path: '/reset-password', name: 'ResetPassword', component: ResetPassword, route: Route },

  { path: '/change-password', name: 'ChangePassword', component: ChangePassword, route: PrivateRoute },
  { path: '/update-profile', name: 'UpdateProfile', component: UpdateProfile, route: PrivateRoute },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute },
  { path: '/products/:id', name: 'ProductDetails', component: ProductDetails, route: PrivateRoute },
  { path: '/refer-a-friend', name: 'ReferAFriend', component: ReferAFriend, route: PrivateRoute },

  { path: '/', exact: true, name: 'Landing', component: Landing, route: Route },
  
]

export { routes, PrivateRoute };