import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    referrals: [],
    error: null,
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REFERRALS:
            return { ...state, loading: true };
        case GET_REFERRALS_SUCCESS:
            return { ...state, referrals: action.payload.data, total: action.payload.total, loading: false, error: null };
        case GET_REFERRALS_FAILED:
            return { ...state, error: action.payload, loading: false };

        default: return { ...state };
    }
}

export default UserReducer;